<template>
  <div id="air">
    <el-dialog
      :title="airFormTitle"
      width="680px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
    >
      <el-form
        ref="airFormRef"
        :model="airForm"
        :rules="airFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceId">
              <el-select
                v-model="airForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="deviceChange"
              >
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input v-model="airForm.deviceNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="airForm.spec" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="更换原因" prop="replaceCause">
              <el-input
                v-model="airForm.replaceCause"
                placeholder="请输入更换原因"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更换过程" prop="replaceProcess">
              <el-input
                v-model="airForm.replaceProcess"
                placeholder="请输入更换过程"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更换结果" prop="replaceResult">
              <el-input
                v-model="airForm.replaceResult"
                placeholder="请输入更换结果"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="airForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更换人" prop="replacement">
              <el-input v-model="airForm.replacement" placeholder="请输入更换人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="airForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更换日期" prop="replaceDate">
              <el-date-picker v-model="airForm.replaceDate" placeholder="请选择更换日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核日期" prop="reviewDate">
              <el-date-picker v-model="airForm.reviewDate" placeholder="请选择复核日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="airFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column label="更换日期">
        <template slot-scope="scope">
          <span v-if="scope.row.replaceDate">{{ scope.row.replaceDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="replacement" label="更换人" />
      <el-table-column prop="replaceCause" label="更换原因" />
      <el-table-column prop="replaceProcess" label="更换过程" />
      <el-table-column prop="replaceResult" label="更换结果" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAir, deleteAir, updateAir, selectAirInfo, selectAirList } from '@/api/device/air'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      airDialogVisible: false,
      airFormTitle: '',
      airForm: {
        id: '',
        deviceId: '',
        deviceName: '',
        deviceNo: '',
        spec: '',
        replaceDate: '',
        replaceCause: '',
        replaceProcess: '',
        replaceResult: '',
        replacement: '',
        remarks: '',
        reviewer: '',
        reviewDate: ''
      },
      airFormRules: {
        deviceId: [{ required: true, message: '设备不能为空', trigger: ['blur', 'change']}]
      },
      airPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      deviceList: []
    }
  },
  created () {
    selectBaseInfoList().then(res => {
      this.deviceList = res.list
    })
    selectAirList(this.searchForm).then(res => {
      this.airPage = res
    })
  },
  methods: {
    airDialogClose () {
      this.$refs.airFormRef.resetFields()
    },
    airFormSubmit () {
      if (this.airFormTitle === '空调过滤器清洗更换记录详情') {
        this.airDialogVisible = false
        return
      }
      this.$refs.airFormRef.validate(async valid => {
        if (valid) {
          if (this.airFormTitle === '新增空调过滤器清洗更换记录') {
            await addAir(this.airForm)
          } else if (this.airFormTitle === '修改空调过滤器清洗更换记录') {
            await updateAir(this.airForm)
          }
          this.airPage = await selectAirList(this.searchForm)
          this.airDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.airFormTitle = '新增空调过滤器清洗更换记录'
      this.airDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAir(row.id)
        if (this.airPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.airPage = await selectAirList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.airFormTitle = '修改空调过滤器清洗更换记录'
      this.airDialogVisible = true
      this.selectAirInfoById(row.id)
    },
    handleInfo (index, row) {
      this.airFormTitle = '空调过滤器清洗更换记录详情'
      this.airDialogVisible = true
      this.selectAirInfoById(row.id)
    },
    selectAirInfoById (id) {
      selectAirInfo(id).then(res => {
        this.airForm.id = res.id
        this.airForm.deviceId = res.deviceId
        this.airForm.deviceName = res.deviceName
        this.airForm.deviceNo = res.deviceNo
        this.airForm.spec = res.spec
        this.airForm.replaceDate = res.replaceDate
        this.airForm.replaceCause = res.replaceCause
        this.airForm.replaceProcess = res.replaceProcess
        this.airForm.replaceResult = res.replaceResult
        this.airForm.replacement = res.replacement
        this.airForm.remarks = res.remarks
        this.airForm.reviewer = res.reviewer
        this.airForm.reviewDate = res.reviewDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAirList(this.searchForm).then(res => {
        this.airPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAirList(this.searchForm).then(res => {
        this.airPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAirList(this.searchForm).then(res => {
        this.airPage = res
      })
    },
    deviceChange (value) {
      if (value) {
        let device = this.deviceList.find(item => item.id === value)
        this.airForm.deviceName = device.deviceName
        this.airForm.deviceNo = device.deviceNo
        this.airForm.spec = device.spec
      } else {
        this.airForm.deviceName = ''
        this.airForm.deviceNo = ''
        this.airForm.spec = ''
      }
    }
  }
}
</script>

<style>
</style>
