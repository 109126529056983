import axios from '@/common/axios'
import qs from 'qs'

export function addAir (data) {
  return axios({
    method: 'post',
    url: '/device/air/add',
    data: qs.stringify(data)
  })
}

export function deleteAir (id) {
  return axios({
    method: 'delete',
    url: '/device/air/delete/' + id
  })
}

export function updateAir (data) {
  return axios({
    method: 'put',
    url: '/device/air/update',
    data: qs.stringify(data)
  })
}

export function selectAirInfo (id) {
  return axios({
    method: 'get',
    url: '/device/air/info/' + id
  })
}

export function selectAirList (query) {
  return axios({
    method: 'get',
    url: '/device/air/list',
    params: query
  })
}
